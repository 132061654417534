import { useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton, IconButton, Typography } from '@mui/material';

import useDomainInfo from 'src/hooks/use-domain-info';
import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import {
  financeApi
  // , useGetBalance
} from 'src/api/finance';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

import { BalanceModal } from './balance-modal';

const currencies = {
  1: '$',
  2: '€',
  3: '£',
  4: 'CA$',
  5: 'A$',
};

export const Balance = () => {
  // const { balance } = useGetBalance();

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const { user, tradingAccount, accounts, balance, setBalance } = useAuthContext();
  const { domainData } = useDomainInfo();

  const getBalance = async () => {
    try {
      setIsLoading(true);
      const res = await financeApi.getPositions();
      setBalance(res?.data?.balance);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error: ", error);
    }
  };

  const getBalanceInfo = async () => {
    setInitialLoading(true);
    try {
      await getBalance();
    } catch (error) {
      console.error("error: ", error);
    }
    setInitialLoading(false);
  };

  useEffect(() => {
    getBalanceInfo();
  }, []);

  const [openBalanceModal, setOpenBalanceModal] = useState(false);

  const mdUp = useResponsive('up', 'md');

  const { t } = useTranslate();

  const currentBalance = useMemo(() => {
    if (tradingAccount) {
      return balance?.[tradingAccount];
    }
    if (localStorage.getItem("trading_account")) {
      return balance?.[localStorage.getItem("trading_account")];
    }
    return balance?.total ? balance?.total : {};
    
  }, [balance, tradingAccount]);

  const marginCall = useMemo(() => {
    if (
      Number(currentBalance?.used_margin) !== 0 &&
      Number(currentBalance?.margine_level) < 100 &&
      Number(currentBalance?.pl) !== 0
    ) {
      return true;
    }
    return false;

  }, [currentBalance]);

  const currency = useMemo(() => {
    if (tradingAccount) {
      const accountCurrency = accounts?.find(acc => acc?.id == tradingAccount)?.currency;
      return currencies[accountCurrency];
    } 
      const accountCurrency = accounts?.find(acc => acc?.id == localStorage.getItem("trading_account"))?.currency;
      return currencies[accountCurrency] ?? currencies[user?.trader_currency];
    
  }, [tradingAccount, accounts, user]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={mdUp ? 3 : 0}
        sx={{
          pl: { md: 0, xs: 12 },
        }}
      >
        {!mdUp && marginCall && (
          <SvgColor
            src="/assets/icons/components/ic_warning.svg"
            sx={{ width: 24, height: 24, background: '#DC2626', mr: 1 }}
          />
        )}

        <Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <SvgColor
              src="/assets/icons/components/ic_balance.svg"
              sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: marginCall ? '#DC2626' : 'text.secondary',
              }}
            >
              {t('Balance')}
            </Typography>
          </Stack>
          {initialLoading ?
            <Skeleton sx={{ mt: 0.5 }} />
            :
            <Typography
              sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}
            >
              {currency}{' '}
              {currentBalance?.balance ? currentBalance.balance.toFixed(2) : '0.00'}
            </Typography>
          }
        </Stack>

        {mdUp ? (
          <>
            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SvgColor
                  src="/assets/icons/components/ic_equity.svg"
                  sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: marginCall ? '#DC2626' : 'text.secondary',
                  }}
                >
                  {t('Equity')}
                </Typography>
              </Stack>

              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: marginCall ? '#DC2626' : 'text.primary',
                    whiteSpace:"nowrap"
                  }}
                >
                  {currency}{' '}
                  {currentBalance?.equity ? currentBalance.equity.toFixed(2) : '0.00'}
                </Typography>
              }
            </Stack>

            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SvgColor
                  src="/assets/icons/components/ic_pl.svg"
                  sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: marginCall ? '#DC2626' : 'text.secondary',
                  }}
                >
                  {t('Open P/L')}
                </Typography>
              </Stack>

              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: marginCall ? '#DC2626' : 'text.primary',
                      whiteSpace:"nowrap"
                    }}
                  >
                    {currency}{' '}
                    {currentBalance?.pl ? currentBalance.pl.toFixed(2) : '0.00'}
                  </Typography>

                  {currentBalance?.pl > 0 ? (
                    <Iconify icon="ph:arrow-up" width={18} color="#00A76F" />
                  ) : (
                    <Iconify icon="ph:arrow-down" width={18} color="#EF4444" />
                  )}
                </Stack>
              }
            </Stack>

            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SvgColor
                  src="/assets/icons/components/ic_margin.svg"
                  sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: marginCall ? '#DC2626' : 'text.secondary',
                  }}
                >
                  {t('Margin level')}
                </Typography>
              </Stack>

              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: marginCall ? '#DC2626' : 'text.primary',
                    whiteSpace:"nowrap"
                  }}
                >
                  {currentBalance?.margine_level ? currentBalance.margine_level.toFixed(2) : '0.00'}%
                </Typography>
              }
            </Stack>

            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SvgColor
                  src="/assets/icons/components/ic_free_margin.svg"
                  sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: marginCall ? '#DC2626' : 'text.secondary',
                  }}
                >
                  {t('Free margin')}
                </Typography>
              </Stack>
              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: marginCall ? '#DC2626' : 'text.primary',
                    whiteSpace:"nowrap"
                  }}
                >
                  {currency}{' '}
                  {currentBalance?.free_margin ? currentBalance.free_margin.toFixed(2) : '0.00'}
                </Typography>
              }
            </Stack>

            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SvgColor
                  src="/assets/icons/components/ic_used_margin.svg"
                  sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: marginCall ? '#DC2626' : 'text.secondary',
                  }}
                >
                  {t('Used margin')}
                </Typography>
              </Stack>

              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: marginCall ? '#DC2626' : 'text.primary',
                    whiteSpace:"nowrap"
                  }}
                >
                  {currency}{' '}
                  {currentBalance?.used_margin ? currentBalance.used_margin.toFixed(2) : '0.00'}
                </Typography>
              }
            </Stack>

            {domainData?.internal_brand?.credit ? (
            
            <Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SvgColor
                    src="/assets/icons/components/ic_credit.svg"
                    sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
                  />
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: marginCall ? '#DC2626' : 'text.secondary',
                    }}
                  >
                    {t('Credit')}
                  </Typography>
                </Stack>

              {initialLoading ?
                <Skeleton sx={{ mt: 0.5 }} />
                :
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: marginCall ? '#DC2626' : 'text.primary',
                    whiteSpace:"nowrap"
                  }}
                >
                  {currency}{' '}
                  {currentBalance?.credit ? Number(currentBalance.credit)?.toFixed(2) : '0.00'}
                </Typography>
              }
            </Stack>
            ) : null }

            {marginCall && (
              <Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip
                    title={
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        py={1.5}
                        px={1}
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: '#EF4444CC',
                          color: '#fff',
                          width: 850,
                          position: 'absolute',
                          top: 10,
                          right: 0,
                        }}
                      >
                        <SvgColor
                          src="/assets/icons/components/ic_warning.svg"
                          sx={{ width: 36, height: 36 }}
                        />

                        <Typography fontWeight={500} fontSize={18}>
                          Margin call: Positions will close on certain margin level!
                        </Typography>
                      </Stack>
                    }
                  >
                    <IconButton>
                      <SvgColor
                        src="/assets/icons/components/ic_warning.svg"
                        sx={{ width: 24, height: 24, background: '#DC2626' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
          </>
        ) : (
          <IconButton onClick={() => setOpenBalanceModal(true)}>
            <Iconify icon="iconamoon:arrow-right-2" width={36} color="text.secondary" />
          </IconButton>
        )}
      </Stack>
      <BalanceModal
        open={openBalanceModal}
        onClose={() => setOpenBalanceModal(false)}
        balance={currentBalance}
        currency={currency}
        marginCall={marginCall}
      />
    </>
  );
};
