import { m } from 'framer-motion';
import { useMemo, useState, useEffect } from 'react';

import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import useDomainInfo from 'src/hooks/use-domain-info';

import { getAPIUrl } from 'src/config';
import { useTranslate } from 'src/locales';
import { financeApi } from 'src/api/finance';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { TraderAccountCreate } from './trader-account-create';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Settings',
    linkTo: paths.dashboard.user.account,
  },
];

const currencyToIcon = {
  1: 'openmoji:flag-united-states',
  2: 'flag:eu-4x3',
  3: 'fxemoji:greatbritainflag',
  4: 'openmoji:flag-canada',
  5: 'openmoji:flag-australia',
};

// ----------------------------------------------------------------------

export const useTraderAccounts = (customerId, setAccounts) => {
  const getAccounts = async () => {
    try {
      const {
        data: { trading_accounts },
      } = await financeApi.getTradingAccounts({ client_id: customerId });
      setAccounts(trading_accounts ?? []);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, [customerId]);

  return {
    getAccounts,
  };
};

export default function AccountPopover() {
  const router = useRouter();
  const { user, logout, setAccount, accounts, setAccounts, tradingAccount: acc } = useAuthContext();
  const { getAccounts } = useTraderAccounts(user?.id, setAccounts);

  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const popover = usePopover();
  const { domainData } = useDomainInfo();

  const [tradingAccount, setTradingAccount] = useState();
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState();

  const handleLogout = async () => {
    try {
      logout();
      popover.onClose();
      router.push('/login');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleToggleSetting = () => {
    settings.onToggle();
  };
  useEffect(() => {
    if (acc) {
      setTradingAccount(acc);
    } else {
      setTradingAccount(localStorage.getItem("trading_account") ?? null);
    }
  }, [acc]);

  const handleSetTradingAccount = (account) => {
    setTradingAccount(account);
    setAccount(account);
    enqueueSnackbar('Successfully switched to trader account!', { variant: 'success' });
  };

  const themeSetting = useMemo(() => {
    if (domainData?.internal_brand) {
      const s = domainData?.internal_brand?.theme_setting
        ? JSON.parse(domainData?.internal_brand?.theme_setting)
        : {};
      return s;
    }
    return null;
  }, [domainData?.internal_brand]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.avatar?.includes('http') ? user?.avatar : `${getAPIUrl()}/${user?.avatar}`}
          alt={user?.full_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.full_name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250, p: 0 }}>
        <Stack sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.full_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>

          {domainData?.internal_brand?.account_type ? (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              Account type: {user?.client_rank_text ?? ''}
            </Typography>
          ) : null}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem
            onClick={() => {
              popover.onClose();
              handleToggleSetting();
            }}
          >
            {t('Settings')}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {accounts?.length > 0 ? (
          <Typography
            sx={{ fontSize: 14, fontWeight: 600, px: 2, pt: 2, pb: 1, textAlign: 'center' }}
          >
            Trading Accounts
          </Typography>
        ) : null}

        {accounts?.map((account) => (
          <Stack sx={{ px: 1.5, py: 0.5 }} key={account?.id}>
            <MenuItem
              sx={{
                direction: 'row',
                alignItems: 'center',
                background: account?.id == tradingAccount ? 'rgba(90, 235, 80, 0.23)' : '',
              }}
              onClick={() => handleSetTradingAccount(account?.id)}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                {account?.demo && <Chip color='warning' size="small" variant="soft" label="Demo" />}
                {account?.active && <Chip color='success' size="small" variant="soft" label="Live" />}
                <Iconify width={15} icon={currencyToIcon[account?.currency]} />
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                <Typography>{account?.name}</Typography>
                <Stack onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAccountToEdit(account);
                  setOpenCreateAccount(true);
                }}>
                  <Iconify icon="lucide:edit" />
                </Stack>
              </Stack>
            </MenuItem>
          </Stack>
        ))}

        {themeSetting?.enable_create_trader_account === undefined || themeSetting?.enable_create_trader_account ? (
          <Stack sx={{ p: 1 }}>
            <MenuItem onClick={() => setOpenCreateAccount(true)}>Create account</MenuItem>
          </Stack>
        ) : null}

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('Logout')}
        </MenuItem>
      </CustomPopover>

      <TraderAccountCreate
        open={openCreateAccount}
        onClose={() => setOpenCreateAccount(false)}
        onGetAccounts={getAccounts}
        isEdit={!!accountToEdit}
        account={accountToEdit}
      />
    </>
  );
}
